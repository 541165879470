import { UploadService } from './route-guards/upload/upload.service';
import { FileService } from './route-guards/file/file.service';
import { UnauthorizedUserComponent } from './Components/unauthorized-user/unauthorized-user.component';
import { UploadFileComponent } from './Components/Upload-File/Upload-File.component';
import { FilesComponent } from './Components/Files/Files.component';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { enableProdMode, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccordionModule } from 'primeng/accordion';
import { MenuItem, MessageService, ConfirmationService } from 'primeng/api';

import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';

import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

enableProdMode();

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

import { detect } from 'detect-browser';
import { KeycloakService } from 'keycloak-angular';
import { HttpConfigInterceptor } from './Interceptors/httpconfig.interceptor';
import { environment } from 'src/environments/environment';
import { BrowserComponent } from './Components/browser/browser.component';
import { ServiceWorkerModule } from '@angular/service-worker';
const keycloakService = new KeycloakService();
const browser = detect();

@NgModule({
  declarations: [
    AppComponent,
    FilesComponent,
    UploadFileComponent,
    UnauthorizedUserComponent,
    BrowserComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    ToastModule,
    TableModule,
    DropdownModule,
    ButtonModule,
    InputTextModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    DialogModule,
    CalendarModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([
      // { path: '', redirectTo: 'files', pathMatch: 'full' },
      { path: 'files', component: FilesComponent, canActivate: [ FileService ] },
      { path: 'upload-file', component: UploadFileComponent, canActivate: [ UploadService ] },
      { path: 'unauthorized-user', component: UnauthorizedUserComponent },
      { path: 'xxxxx', component: BrowserComponent }
    ])
  ],
  providers: [
    CookieService,
    MessageService,
    ConfirmationService,
    MessageService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: KeycloakService,
      useValue: keycloakService
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  //  bootstrap: [
  //     AppComponent
  //  ]
   entryComponents: [
    AppComponent
  ]
})
export class AppModule {
  constructor(
     ) {
     // localStorage.clear();
     localStorage.setItem('oi', '');
     localStorage.setItem('ui', '');
   }

   ngDoBootstrap(app) {
    localStorage.setItem('browser', 'true');
    keycloakService
      .init(
        {
          config: {
            url: environment.keycloakUrl + '/auth',
            realm: environment.realm,
            clientId: environment.clientid
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          // enableBearerInterceptor: true,
          // bearerPrefix: 'Bearer',
          // bearerExcludedUrls: [
          //     '/assets',
          //     '/clients/public']
        })
      .then(async () => {
        // console.log('[ngDoBootstrap] bootstrap app');

        // console.log((await keycloakService.loadUserProfile()).email)

        localStorage.setItem('oi', '');
        localStorage.setItem('ui', '');
        app.bootstrap(AppComponent);
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production });
      })
      .catch(
        error => console.error('[ngDoBootstrap] init Keycloak failed', error)
      );
   }
}
