export class Files {
    public FileId: string;
    public ExternalFileName: string;
    public Created: Date;
    public BucketName: string;
    public ContentType: string;
    public NewFile: FormData;
}

export class DeleteFile {
    public BucketName: string;
    public FileName: string;
}
