import { UserService } from './../../Services/user/user.service';
import { FileService } from './../../Services/file/file.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { DeleteFile } from 'src/app/Models/file';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

import * as FileSaver from 'file-saver';
import { Variables } from 'src/app/Models/variables';

@Component({
  selector: 'app-files',
  templateUrl: './Files.component.html',
  styleUrls: ['./Files.component.scss']
})
export class FilesComponent implements OnInit {

  disable = true;
  loading = false;
  files = [];
  display = false;
  isUrl = false;
  url = '';

  page = 1;
  size = 100;
  item: any;

  filters = [];
  filter: any;

  fileid = ' ';
  filename = ' ';
  bucket = 'flm-test-bucket';
  bucketname = ' ';
  // bucketname = 'flm-bucket';
  range: any;
  start = ' ';
  end = ' ';

  isfileid = false;
  isfilename = false;
  isbucketname = false;
  isrange = false;

  isupload = false;

  constructor(
    private fileService: FileService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private userService: UserService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.NONE').subscribe((resp1: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.FILEID').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.FILENAME').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BUCKETNAME').subscribe((resp4: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.CREATEDTIMERANGE').subscribe((resp5: string) => {
              this.filters.push({label: resp1 + ' ', value: 'None'});
              this.filters.push({label: resp2 + ' ', value: 'FileId'});
              this.filters.push({label: resp3 + ' ', value: 'FileName'});
              this.filters.push({label: resp4 + ' ', value: 'BucketName'});
              this.filters.push({label: resp5 + ' ', value: 'Range'});
            });
          });
        });
      });
    });

    this.getFiles();
   }

  ngOnInit() {
    const v = new Variables();
    const va = localStorage.getItem('ups');
    const perm = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    if ((perm !== undefined && perm !== null && perm.includes('FLM.Files.Upload'))
    || (perm !== undefined && perm !== null && perm.includes('FLM.Administration.Full'))) {
      this.isupload = true;
    }
  }

  gotoUploadFile() {
    this.router.navigateByUrl('/upload-file');
  }

  getFiles() {
    let isadmin = false;
    const v = new Variables();
    const va = localStorage.getItem('ups');
    const perm = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    if (perm !== undefined && perm !== null && perm.includes('FLM.Administration.Full')) {
      isadmin = true;
    }
    this.loading = true;
    this.disable = true;
    this.display = true;
    // tslint:disable-next-line: deprecation
    this.fileService.getFiles(this.page, this.size, this.fileid, this.filename, this.bucketname, this.start, this.end).subscribe((res) => {
      if (res != null) {
        if (res.length === this.size) {
          this.disable = false;
        }

        let count = 0;
        for (const val of res) {
          const newdate = new Date(val.created);
          val.crt = new Date(new Date(newdate.getFullYear(), newdate.getMonth(), newdate.getDate(),
                              newdate.getHours(), newdate.getMinutes(), newdate.getSeconds()).toString()
                              + 'UTC').toLocaleString();
          const options = [];
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.GETURL').subscribe((resp1: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.DOWNLOAD').subscribe((resp2: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
                options.push({label: resp1, value: 'GetUrl'});
                options.push({label: resp2, value: 'Download'});

                if (isadmin === true) {
                  options.push({label: resp3, value: 'Delete'});
                }
              });
            });
          });

          val.options = options;
          this.files.push(val);
          count += 1;
          if (count === res.length) {
            this.loading = false;
            this.display = false;
          }
        }
      } else {
        this.disable = true;
        this.loading = false;
        this.display = false;
      }
    }, err => this.handleError(err));
  }

  selectedFilter(event) {
    if (event.value === 'None') {
      this.isfileid = false;
      this.isfilename = false;
      this.isbucketname = false;
      this.isrange = false;
    } else if (event.value === 'FileId') {
      this.isfileid = true;
      this.isfilename = false;
      this.isbucketname = false;
      this.isrange = false;
      this.fileid = '';
    } else if (event.value === 'FileName') {
      this.isfileid = false;
      this.isfilename = true;
      this.isbucketname = false;
      this.isrange = false;
      this.filename = '';
    } else if (event.value === 'BucketName') {
      this.isfileid = false;
      this.isfilename = false;
      this.isbucketname = true;
      this.isrange = false;
      this.bucketname = '';
    } else if (event.value === 'Range') {
      this.isfileid = false;
      this.isfilename = false;
      this.isbucketname = false;
      this.isrange = true;
    }
  }

  ok() {
    if (this.filter === undefined) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTOPTION').subscribe((res) => {
        this.confirm(res);
      });
      return;
    }

    if (this.filter === 'None') {
      this.fileid = ' ';
      this.filename = ' ';
      this.bucketname = ' ';
      this.start = ' ';
      this.end = ' ';
      this.range = null;
    }

    if (this.isfileid === true) {
      if (this.fileid === undefined || this.fileid === null || this.fileid.trim() === '') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ENTERFILEID').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      this.filename = ' ';
      this.bucketname = ' ';
      this.start = ' ';
      this.end = ' ';
      this.range = null;
    }

    if (this.isfilename === true) {
      if (this.filename === undefined || this.filename === null || this.filename.trim() === '') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ENTERFILENAME').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      this.fileid = ' ';
      this.bucketname = ' ';
      this.start = ' ';
      this.end = ' ';
      this.range = null;
    }

    if (this.isbucketname === true) {
      if (this.bucketname === undefined || this.bucketname === null || this.bucketname.trim() === '') {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ENTERBUCKETNAME').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      this.fileid = ' ';
      this.filename = ' ';
      this.start = ' ';
      this.end = ' ';
      this.range = null;
    }

    if (this.isrange === true) {
      if (this.range === undefined || this.range === null) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }
      if (this.range[0] !== undefined && this.range[0] !== null) {
        this.start = this.range[0].toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      if (this.range[1] !== undefined && this.range[1] !== null) {
        this.end = this.range[1].toDateString();
      } else {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SELECTTIMERANGE').subscribe((res) => {
          this.confirm(res);
        });
        return;
      }

      this.fileid = ' ';
      this.filename = ' ';
      this.bucketname = ' ';
    }

    this.files = [];
    this.page = 1;
    this.getFiles();
  }

  loadMore() {
    this.page += 1;
    this.getFiles();
  }

  saveFile(event, file) {
    localStorage.setItem('file', JSON.stringify(file));
    if (event.value === 'Download') {
      this.downloadFile(file);
    } else if (event.value === 'Delete') {
      this.deleteFile(file);
    } else if (event.value === 'GetUrl') {
      this.getFile(file);
    }
    this.item = [];
  }

  downloadFile(file) {
    console.log(file)
    // tslint:disable-next-line: deprecation
    this.fileService.getFile(file.bucketName, file.fileId).subscribe((res) => {
      console.log(res)
      if (res != null) {
        // tslint:disable-next-line: deprecation
        this.fileService.downloadFile(res.url, res.metaData['Content-Type']).subscribe((res2) => {
          console.log(res2)
          FileSaver.saveAs(res2, file.externalFileName);
        }, err => this.handleError(err));
      }
    }, err => this.handleError(err));
  }

  getFile(file) {
    this.display = true;
    // tslint:disable-next-line: deprecation
    this.fileService.getFile(file.bucketName, file.fileId).subscribe((res) => {
      if (res != null) {
        this.url = res.url;
        this.isUrl = true;
        this.display = false;
      }
    }, err => this.handleError(err));
  }

  deleteFile(file) {
    const del = new DeleteFile();
    del.BucketName = file.bucketName;
    del.FileName = file.fileId;

    this.display = true;
    // tslint:disable-next-line: deprecation
    this.fileService.deleteFile(del).subscribe((res) => {
      if (res === true) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.FILEDELETED').subscribe((trans) => {
          this.showSuccess(trans);
        });
        this.display = false;

        this.filter = 'None';
        this.isfileid = false;
        this.isfilename = false;
        this.isbucketname = false;
        this.isrange = false;
        this.fileid = ' ';
        this.filename = ' ';
        this.bucketname = ' ';
        this.start = ' ';
        this.end = ' ';

        this.files = [];
        this.page = 1;
        this.getFiles();
      } else {
        this.display = false;
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.COULDNOTDELETEFILE').subscribe((trans) => {
          this.confirm(trans);
        });
      }
    }, err => this.handleError(err));
  }

  copyLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.LINKCOPIED').subscribe((trans) => {
      this.showSuccess2(trans);
    });
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        },
        reject: () => {
          //
        }
    });
  }

  showSuccess(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: trans, detail: msg});
    });
  }

  showSuccess2(msg) {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: '', detail: msg});
    });
  }

  setTestRange() {
    this.range = [new Date(new Date().getTime() - 3600000), new Date(new Date().getTime() + 3600000)];
  }

  handleError(err) {
    console.log(err)
    this.loading = false;
    this.display = false;
    if (err.error.message === 'MinIO API responded with message=Not found.') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.BUCKETNOTFOUND').subscribe((trans) => {
        this.confirm(trans);
      });
    }
  }

}
