import { BucketService } from './../../Services/bucket/bucket.service';
import { FileService } from '../../Services/file/file.service';
import { Files } from './../../Models/file';
import { Component, OnInit, ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ɵAnimationGroupPlayer } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-file',
  templateUrl: './Upload-File.component.html',
  styleUrls: ['./Upload-File.component.scss']
})
export class UploadFileComponent implements OnInit {

  buckets = [];
  bucketname: string;
  filename: string;
  file: any;
  sanitizedfile: any;
  type: any;

  display = false;
  formdata: FormData;
  blob: Blob;

  constructor(
    private fileService: FileService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private bucketService: BucketService,
    private location: Location,
    private domSanitizer: DomSanitizer
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.getBuckets();
  }

  ngOnInit() {
  }

  getBuckets() {
    this.display = true;
    this.bucketService.getBuckets().subscribe((res) => {
      let count = 0;
      if (res != null) {
        for (const val of res) {
          this.buckets.push({ label: val.name, value: val.name });
          count += 1;
          if (count === res.length) {
            this.display = false;
          }
        }
      } else {
        this.display = false;
      }
    }, err => this.handleError(err));
  }

  public uploadFile(files) {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files[0] as File;
    this.filename = fileToUpload.name;
    this.formdata = new FormData();
    this.formdata.append(this.bucketname, fileToUpload, this.filename);

    const file = new Files();
    file.BucketName = this.bucketname;
    file.ContentType = fileToUpload.type;
    file.ExternalFileName = fileToUpload.name;
    file.NewFile = this.formdata;
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.filename = inputNode.files[0].name;
        const base64 = e.target.result;
        this.file = this.domSanitizer.bypassSecurityTrustUrl(base64);
        this.blob = this.dataURItoBlob(base64);
        this.type = this.blob.type;

        /*const file = new Files();
        file.BucketName = 'flm-test-bucket';
        file.ContentType = 'image/svg+xml';
        file.ExternalFileName = 'mysvg';
        file.NewFile = this.formdata;*/
      };
      reader.onerror = ((err) => {
        console.log(err);
      });
    }
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  onUpload() {
    if (this.bucketname === undefined || this.bucketname === null || this.bucketname.trim() === '') {
      this.translate.get('GENERAL.SELECTBUCKET').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    if (this.file === undefined || this.file === null || this.file.toString().trim() === '') {
      this.translate.get('GENERAL.SELECTFILE').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    if (this.filename === undefined || this.filename === null || this.filename.trim() === '') {
      this.translate.get('GENERAL.ENTERFILENAME').subscribe((trans) => {
        this.confirm(trans);
      });
      return;
    }

    this.formdata = new FormData();
    this.formdata.append('file', this.blob, this.bucketname + ':' + this.filename);
    this.display = true;
    this.fileService.postFile(this.formdata).subscribe((res) => {
      if (res !== undefined && res !== null && res.toString().trim() !== '') {
        this.translate.get('GENERAL.FILEUPLOADED').subscribe((trans) => {
          this.showSuccess(trans);
        });
        this.display = false;
        this.location.back();
      } else {
        this.translate.get('GENERAL.ERRORUPLOADINGFILE').subscribe((trans) => {
          this.confirm(trans);
        });
        this.display = false;
      }
    }, err => this.handleError(err));
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        },
        reject: () => {
          //
        }
    });
  }

  showSuccess(msg) {
    this.translate.get('GENERAL.SUCCESS').subscribe((trans) => {
      this.messageService.add({key: 'tc', severity: 'success', summary: trans, detail: msg});
    });
  }

  handleError(err) {
    this.display = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_file_ext_name_key"') {
      this.translate.get('GENERAL.FILEALREADYEXISTS').subscribe((trans) => {
        this.confirm(trans);
      });
    } else if (err.error.Message === 'Bucket not found' || err.error.Message === 'MinIO API responded with message=Not found.') {
      this.translate.get('GENERAL.BUCKETNOTFOUND').subscribe((trans) => {
        this.confirm(trans);
      });
    } else {
      console.log(err);
    }
  }

}
