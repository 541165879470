import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Models/urls';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Variables } from 'src/app/Models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.usmApiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();

      this.orgId = +oi;
  }

  getUserRoles(uid: number, gid: number) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/userroles/' + uid + '/' + gid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserGroupsByGroupIdUserId(orgid: number, gid: number, uid: number) {
    // this.orgId = +localStorage.getItem('organisation-id');
    this.orgId = orgid;
    // tslint:disable-next-line: max-line-length
    const url = this.url.usmApiUrl + '/api/usergroups/Get_ByUserId/' + this.orgId + '/' + gid + '/' + uid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserInfo(uid, empid, email, phone) {
    // tslint:disable-next-line: max-line-length
    const url = this.url.usmApiUrl + '/api/users/ ' + uid + '/' + empid + '/' + email + '/' + phone + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }

}
