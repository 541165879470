import { LanguageService } from './Services/language/language.service';
import { FileService } from './Services/file/file.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from './Services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { GroupService } from './Services/group/group.service';
import { PermissionService } from './Services/permission/permission.service';
import { ConfirmationService } from 'primeng/api';
import { Variables } from './Models/variables';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  loading = false;
  load = true;

  uid = 0;

  appicon: HTMLLinkElement = document.querySelector('#appicon');

  infoText = 'Information';
  closeText = 'Close';

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private groupService: GroupService,
    private userService: UserService,
    private permService: PermissionService,
    private keycloakService: KeycloakService,
    private fileService: FileService,
    private language: LanguageService
  ) {
      if ('serviceWorker' in navigator) {
        caches.keys().then(function(cacheNames) {
          cacheNames.forEach(function(cacheName) {
            caches.delete(cacheName);
          });
        });
      }

    // localStorage.clear();
    router.navigateByUrl('');
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.loading = true;

    /*const o = CryptoJS.AES.encrypt('1', pass);
    const u = CryptoJS.AES.encrypt('165', pass);
    this.cookieService.set('goepla-portal-organisation-id', o, 1000, '/', 'localhost', false, 'Lax');
    this.cookieService.set('goepla-portal-user-id', u, 1000, '/', 'localhost', false, 'Lax');*/

    // const org = this.cookieService.get('goepla-portal-organisation-id');
    // const usr = this.cookieService.get('goepla-portal-user-id');
    // const decorg = CryptoJS.AES.decrypt(org, pass).toString(CryptoJS.enc.Utf8);
    // const decusr = CryptoJS.AES.decrypt(usr, pass).toString(CryptoJS.enc.Utf8);

    // const oid = decorg;
    // const uid = decusr;
    // if (oid.trim() === '') {
    //   this.load = false;
    //   translate.get('GENERAL.MODULEINITIALISATIONFAILED').subscribe((res) => {
    //     this.confirm(res);
    //   });
    //   return;
    // }

    // if (uid.trim() === '') {
    //   this.load = false;
    //   translate.get('GENERAL.MODULEINITIALISATIONFAILED').subscribe((res) => {
    //     this.confirm(res);
    //   });
    //   return;
    // }

    // const v = new Variables();
    // const oi = CryptoJS.AES.encrypt(oid, v.pass);
    // const ui = CryptoJS.AES.encrypt(uid, v.pass);
    // localStorage.setItem('oi', oi);
    // localStorage.setItem('ui', ui);
    // this.uid = +uid;
  }

  async ngOnInit() {
    // if (this.load === false) {
    //   return;
    // }
    this.load = true;
    if (localStorage.getItem('browser') === null || localStorage.getItem('browser') === 'false') {
      // this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
      //   this.confirm(resp);
      // });
      await this.showInfo('browser');
      return;
    }

    await this.setIcon();
    await this.getUser();
  }

  async setIcon() {
    this.loading = true;
    const file = await this.fileService.getFiles(1, 1, ' ', 'flm-icon', 'flm-bucket', ' ', ' ')
    .toPromise().catch(err => this.handleError(err));
    if (file !== undefined && file !== null && file.length > 0) {
      const furl = await this.fileService.getFile('flm-bucket', file[0].fileId)
      .toPromise().catch(err => this.handleError(err));
      if (furl !== undefined && furl !== null && furl.url !== null) {
        this.appicon.href = furl.url;
      }
    }
    this.loading = false;
  }

  async showInfo(info) {
    this.loading = true;
    if (this.keycloakService === undefined || this.keycloakService === null
      || this.keycloakService.getKeycloakInstance() === undefined
      || this.keycloakService.getKeycloakInstance() === null) {
      if (info === 'browser') {
        this.load = false;
        // this.confirm('Browser doesn\'t fulfill the browser requirements:' +
        // ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('message', 'Browser doesn\'t fulfill the browser requirements:' +
        ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      } else if (info === 'user') {
        // this.confirm('USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
        localStorage.setItem('message', 'USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      }
      this.loading = false;
      return;
    } else {
      if (localStorage.getItem('language') === null) {
        // Replace the user id with the users user id later
        const lang = await this.language.getLanguage(1).toPromise().catch(err => this.handleError(err));
        if (lang.userId > 0) {
          localStorage.setItem('language', lang.lang);

          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        } else {
          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        }

        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.INFO').subscribe((resp) => {
          this.infoText = resp;
        });

        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CLOSE').subscribe((resp) => {
          this.closeText = resp;
        });

        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      } else {
        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      }
    }

    this.loading = false;
  }

  async getUser() {
    this.loading = true;
    const eml = (await this.keycloakService.loadUserProfile()).email;

    const ress = await this.userService.getUserInfo(0, 0, eml, null)
    .toPromise().catch(err => this.handleError(err));

    if (ress !== undefined && ress !== null && ress.userId > 0) {
      const v = new Variables();
      const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
      const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
      localStorage.setItem('oi', oid);
      localStorage.setItem('ui', uid);

      this.uid = ress.userId;

      await this.showInfo('null');
      await this.getGroups();
    } else {
      this.loading = false;
      // this.router.navigateByUrl('/unauthorizeduser');
      // this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
      //   this.confirm(resp);
      // });
      await this.showInfo('user');
    }
  }

  async getGroups() {
    this.loading = true;
    const uid = this.uid;
    let mygroups = '';
    let username = '';
    let groupids = '';
    const usergroups = await this.groupService.getUserGroups(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }
    // localStorage.setItem('my-groups', mygroups);

    const user = await this.userService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));
    if (user != null && user.firstName != null && user.lastName != null) {
      username = user.firstName + ' ' + user.lastName;
    }

    // localStorage.setItem('user-name', username);
    // localStorage.setItem('user-group-ids', groupids);

    await this.getPermissions(groupids, user.organisationName, mygroups);

    this.loading = false;
  }

  async getPermissions(groupids, org, mygroups) {
    this.loading = true;
    const permissions = [];
    if (groupids != null) {
      const gids = groupids.split(':');
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, id, ' ').toPromise()
            .catch(err => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                // localStorage.setItem(perm.perm, perm.perm);
                permissions.push(perm.perm);
              }
            }
          }
        }
      }
    }

    if (org === 'ORG_SUPER_ADMIN' || mygroups.includes('Administrators-Full_Group')) {
      permissions.push('FLM.Administration.Full');
    }

    const v = new Variables();
    const p = CryptoJS.AES.encrypt(JSON.stringify(permissions), v.pass).toString();
    localStorage.setItem('ups', p);

    await this.buildMenu(permissions);
    this.loading = false;
  }

  async buildMenu(perm) {
    if (perm != null && perm.length > 0) {
      if (perm.includes('FLM.Section.Files') || perm.includes('FLM.Administration.Full')) {
        this.router.navigateByUrl('/files');
      } else {
        this.load = false;
        this.router.navigateByUrl('/unauthorized-user');
      }
    } else {
      this.load = false;
      this.router.navigateByUrl('/unauthorized-user');
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    console.log(err);
  }
}
