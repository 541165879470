import { DeleteFile } from './../../Models/file';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Urls } from '../../Models/urls';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getFiles(page, size, fileid, filename, bucketname, start, end) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/files/' + page + '/' + size + '/' + fileid + '/' + filename
     + '/' + bucketname + '/' + start + '/' + end + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getFile(bucketname, filename) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/files/' + bucketname + '/' + filename + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  downloadFile(url, type) {
    let headers = new HttpHeaders();
    headers = headers.set('Remove', '');
    headers = headers.set('Accept', type);
    return this.httpclient.get(url, { headers, responseType: 'blob' });
  }

  postFile(file) {
    return this.httpclient.post(this.apiUrl + '/api/files', file)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteFile(file: DeleteFile): Observable<any> {
    const del = JSON.stringify(file);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/files/' + del, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
